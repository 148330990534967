<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-card-title>
              <b-row>
                <b-col>
                  <div>
                    <span class="d-block">
                      Soal Ujian : {{ soalUjianData.length }}
                    </span>
                    <i
                      :class="
                        sumBobotSoal < 1 ? 'd-block text-danger' : 'd-block'
                      "
                    >
                      Jumlah Bobot Soal : {{ sumBobotSoal }}
                    </i>
                  </div>
                </b-col>
                <b-col>
                  <div align="right">
                    <b-button
                      variant="outline-primary"
                      v-b-modal.modal-sm
                      @click.prevent="openBanksoalModal"
                      class="btn-icon"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />Tambah Soal
                      yang tersedia</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-card-title>
            <b-row>
              <b-col md="3" sm="4" class="my-1">
                <b-form-group class="mb-0">
                  <label class="d-inline-block text-sm-left mr-50"
                    >Per page</label
                  >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage2"
                    size="sm"
                    :options="pageOptions2"
                    class="w-50"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="8" class="my-1">
                <b-form-group
                  label="Sort"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-select
                      id="sortBySelect"
                      v-model="sortBy2"
                      :options="sortOptions2"
                      class="w-50"
                    >
                      <template v-slot:first>
                        <option value="">-- none --</option>
                      </template>
                    </b-form-select>
                    <b-form-select
                      v-model="sortDesc2"
                      size="sm"
                      :disabled="!sortBy2"
                      class="w-30"
                    >
                      <option :value="false">Asc</option>
                      <option :value="true">Desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="5" class="my-1">
                <b-form-group label-for="filterInput" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter2"
                      type="search"
                      placeholder="Tekan enter untuk cari"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter2"
                        @click="
                          filter2 = '';
                          getData();
                        "
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-card
                  text-variant="danger"
                  body-class="text-center"
                  border-variant="danger"
                  class="mt-1"
                  v-if="soalUjianData.length == 0"
                >
                  Soal belum ditambahkan
                </b-card>
                <b-table
                  v-if="soalUjianData.length > 0"
                  small
                  responsive="sm"
                  :per-page="perPage2"
                  :current-page="currentPage2"
                  :sort-by.sync="sortBy2"
                  :sort-desc.sync="sortDesc2"
                  :sort-direction="sortDirection2"
                  :filter="filter2"
                  :filter-included-fields="filterOn2"
                  @filtered="onFiltered2"
                  :fields="fields2"
                  :items="soalUjianData"
                >
                  <template #cell(hapus)="{ item }">
                    <feather-icon
                      icon="TrashIcon"
                      size="24"
                      class="text-danger cursor-pointer"
                      @click="removeSavedSoal(item)"
                    ></feather-icon>
                  </template>
                  <!-- A virtual column -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(show_details)="row">
                    <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                    >
                      <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                          <i class="vs-icon feather icon-check" />
                        </span>
                      </span>
                      <span class="vs-label">{{
                        row.detailsShowing ? "Hide" : "Show"
                      }}</span>
                    </b-form-checkbox>
                  </template>

                  <template #row-details="row">
                    <b-card>
                      <b-row class="">
                        <b-col md="4" class="mb-1">
                          <strong>Tipe Soal : </strong
                          >{{
                            row.item.type == "pg"
                              ? "PG"
                              : row.item.type == "multiple_choice"
                              ? "Multiple Choice"
                              : row.item.type == "benar_salah"
                              ? "Benar Salah"
                              : "Pauli"
                          }}
                        </b-col>
                        <b-col
                          md="4"
                          class="mb-1"
                          v-if="
                            row.item.type == 'pg' ||
                            row.item.type == 'multiple_choice' ||
                            row.item.type == 'benar_salah'
                          "
                        >
                          <strong>Bobot : </strong>{{ row.item.bobot }}
                        </b-col>
                        <b-col
                          md="4"
                          class="mb-1"
                          v-if="row.item.type == 'pauli'"
                        >
                          <strong>Waktu : </strong
                          >{{ row.item.timer_second }} detik
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="
                          row.item.type == 'pg' ||
                          row.item.type == 'multiple_choice' ||
                          row.item.type == 'benar_salah'
                        "
                      >
                        <b-col class="mb-1">
                          <strong>Pertanyaan : </strong>
                          <b-card border-variant="dark">
                            <b-card-text
                              :id="row.item.id"
                              v-html="row.item.content"
                            ></b-card-text>
                          </b-card>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="">
                          <div
                            v-if="
                              row.item.type == 'pg' ||
                              row.item.type == 'multiple_choice' ||
                              row.item.type == 'benar_salah'
                            "
                          >
                            <!-- <b-tabs class=""> -->

                            <div
                              v-for="(data, index) in row.item.options"
                              :key="index"
                            >
                              <b-card border-variant="dark">
                                <!-- <template #title>
                                      <div> -->
                                <b-col>
                                  <b-container class="bv-example-row">
                                    <b-row class="justify-content-md-center">
                                      <template v-if="row.item.type == 'pg'">
                                        <b-col md="1">
                                          <div
                                            :class="
                                              data.is_correct == 1
                                                ? 'bg-success'
                                                : 'bg-white'
                                            "
                                          >
                                            {{
                                              index + 1 == 1
                                                ? "A. "
                                                : index + 1 == 2
                                                ? "B. "
                                                : index + 1 == 3
                                                ? "C. "
                                                : index + 1 == 4
                                                ? "D. "
                                                : index + 1 == 5
                                                ? "E. "
                                                : "-"
                                            }}
                                          </div>
                                          <!-- <p> -->
                                        </b-col>
                                        <b-col md="11">
                                          <div
                                            :id="data.id"
                                            v-html="data.content"
                                          ></div>
                                          <div
                                            cols="12"
                                            v-if="
                                              ujianDataId.type_score_calculation ==
                                              'option_point_based'
                                            "
                                          >
                                            <div class="m-1">
                                              <strong
                                                ><i
                                                  >Poin Opsi:
                                                  {{ data.point }}</i
                                                ></strong
                                              >
                                            </div>
                                          </div>
                                          <!-- </p> -->
                                        </b-col>
                                      </template>
                                      <template
                                        v-if="
                                          row.item.type == 'multiple_choice' ||
                                          row.item.type == 'benar_salah'
                                        "
                                      >
                                        <b-col
                                          :class="
                                            data.is_correct == 1
                                              ? 'bg-success'
                                              : 'bg-white'
                                          "
                                          md="1"
                                        >
                                          <!-- <p> -->
                                          {{
                                            index + 1 == 1
                                              ? "A. "
                                              : index + 1 == 2
                                              ? "B. "
                                              : index + 1 == 3
                                              ? "C. "
                                              : index + 1 == 4
                                              ? "D. "
                                              : index + 1 == 5
                                              ? "E. "
                                              : "-"
                                          }}
                                        </b-col>
                                        <b-col md="8">
                                          <span
                                            :id="data.id"
                                            v-html="data.content"
                                          ></span>
                                          <!-- </p> -->
                                        </b-col>
                                        <b-col col lg="3">
                                          <div>
                                            <label
                                              class="font-weight-bold"
                                              v-if="index + 1 == 1"
                                              >Jawaban Opsi. A</label
                                            >
                                            <label
                                              class="font-weight-bold"
                                              v-if="index + 1 == 2"
                                              >Jawaban Opsi. B</label
                                            >
                                            <label
                                              class="font-weight-bold"
                                              v-if="index + 1 == 3"
                                              >Jawaban Opsi. C</label
                                            >
                                            <label
                                              class="font-weight-bold"
                                              v-if="index + 1 == 4"
                                              >Jawaban Opsi. D</label
                                            >
                                            <label
                                              class="font-weight-bold"
                                              v-if="index + 1 == 5"
                                              >Jawaban Opsi. E</label
                                            >
                                            <b-form-checkbox
                                              :id="data.id"
                                              v-if="
                                                row.item.type ==
                                                'multiple_choice'
                                              "
                                              v-model="data.is_correct"
                                              value="1"
                                              class="custom-control-primary"
                                            >
                                              Kunci Jawaban
                                            </b-form-checkbox>
                                          </div>
                                          <div
                                            v-if="
                                              row.item.type == 'benar_salah'
                                            "
                                          >
                                            {{
                                              data.is_true_or_false == "benar"
                                                ? "Benar"
                                                : "Salah"
                                            }}
                                          </div>
                                        </b-col>
                                      </template>
                                    </b-row>
                                  </b-container>
                                </b-col>
                              </b-card>
                            </div>
                          </div>
                          <div v-if="row.item.type == 'pauli'">
                            <!-- <b-tabs class=""> -->

                            <div
                              v-for="(data, index) in row.item.sub_soals"
                              :key="index"
                            >
                              <!-- <b-col> -->
                              <b-container class="bv-example-row">
                                <b-row class="justify-content-md-center">
                                  <b-col md="2">
                                    <b-card border-variant="dark">
                                      <span
                                        :id="data.id"
                                        v-html="data.content"
                                      ></span>
                                    </b-card>
                                  </b-col>
                                  <b-col md="2">
                                    <b-card border-variant="dark">
                                      <span
                                        :id="data.id"
                                        v-html="data.secret_key"
                                      ></span>
                                    </b-card>
                                  </b-col>
                                </b-row>
                              </b-container>
                              <!-- </b-col> -->
                            </div>
                          </div>
                        </b-col>
                      </b-row>

                      <b-button
                        size="sm"
                        variant="outline-secondary"
                        @click="row.toggleDetails"
                        class="mt-1"
                      >
                        Hide Details
                      </b-button>
                    </b-card>
                  </template>
                  <!-- A custom formatted column -->
                  <template #cell(action)="row">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="ModalHapus(row.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template #cell(tgl)="data">
                    {{ humanDate(data.item.info_start) }} s/d
                    {{ humanDate(data.item.info_end) }}
                  </template>
                  <template #cell(max_quota)="data">
                    {{ formatRupiah(data.item.max_quota) }}
                  </template>

                  <template #cell(color)="data">
                    <b-badge :variant="data.item.color">
                      {{ data.item.color }}
                    </b-badge>
                  </template>
                  <template #cell(type)="data">
                    {{
                      data.item.type == "pg"
                        ? "PG"
                        : data.item.type == "multiple_choice"
                        ? "Multiple Choice"
                        : data.item.type == "benar_salah"
                        ? "Benar Salah"
                        : "Pauli"
                    }}
                  </template>
                </b-table>

                <b-pagination
                  v-model="currentPage2"
                  :total-rows="totalRows2"
                  :per-page="perPage2"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- {{ ujianDataId.soal_ids }} -->
      </b-col>
    </b-row>
    <b-modal
      title="Atur Soal Ujian di Jadwal"
      id="banksoal-modal"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row>
        <b-col cols="12">
          <b-card no-body>
            <b-overlay :show="loading">
              <b-card-body>
                <h4>
                  <strong>Bank Soal ({{ totalRows }})</strong>
                </h4>
                <b-card-text class="blog-content-truncate">
                  <b-row>
                    <b-col
                      cols="12"
                      v-if="ujianDataId.category_paket"
                      class="mb-1"
                    >
                      <strong
                        >Kategori Paket:
                        <span class="text-danger">{{
                          ujianDataId?.category_paket?.name
                        }}</span></strong
                      >
                    </b-col>
                    <b-col md="3" sm="13" lg="4" class="">
                      <label>Pilih Tipe</label>
                      <v-select
                        label="value"
                        v-model="filterTipe"
                        class="select-size-sm w-full"
                        @input="getData()"
                        :clearable="true"
                        :options="optTipe"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3" sm="4" class="my-1">
                      <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50"
                          >Per page</label
                        >
                        <b-form-select
                          id="perPageSelect"
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          class="w-50"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="5" class="my-1">
                      <b-form-group label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                          <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Tekan enter untuk cari"
                          />
                          <b-input-group-append>
                            <b-button
                              :disabled="!filter"
                              @click="
                                filter = '';
                                getData();
                              "
                            >
                              Clear
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-table
                        small
                        responsive
                        striped
                        :per-page="perPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :fields="fields"
                        :items="soalData"
                      >
                        <!-- A virtual column -->
                        <template #cell(index)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(selected)="{ rowSelected }">
                          <template v-if="rowSelected">
                            <i class="feather icon-disc primary" />
                          </template>

                          <template v-else>
                            <i class="feather icon-circle" />
                          </template>
                        </template>

                        <template #cell(show_details)="row">
                          <b-form-checkbox
                            v-model="row.detailsShowing"
                            plain
                            class="vs-checkbox-con"
                            @change="row.toggleDetails"
                          >
                            <span class="vs-checkbox">
                              <span class="vs-checkbox--check">
                                <i class="vs-icon feather icon-check" />
                              </span>
                            </span>
                            <span class="vs-label">{{
                              row.detailsShowing ? "Hide" : "Show"
                            }}</span>
                          </b-form-checkbox>
                        </template>

                        <template #row-details="row">
                          <b-card>
                            <b-row class="">
                              <b-col md="4" class="mb-1">
                                <strong>Tipe Soal : </strong
                                >{{
                                  row.item.type == "pg"
                                    ? "PG"
                                    : row.item.type == "multiple_choice"
                                    ? "Multiple Choice"
                                    : row.item.type == "benar_salah"
                                    ? "Benar Salah"
                                    : "Pauli"
                                }}
                              </b-col>
                              <b-col
                                md="4"
                                class="mb-1"
                                v-if="
                                  row.item.type == 'pg' ||
                                  row.item.type == 'multiple_choice' ||
                                  row.item.type == 'benar_salah'
                                "
                              >
                                <strong>Bobot : </strong>{{ row.item.bobot }}
                              </b-col>
                              <b-col
                                md="4"
                                class="mb-1"
                                v-if="row.item.type == 'pauli'"
                              >
                                <strong>Waktu : </strong
                                >{{ row.item.timer_second }} detik
                              </b-col>
                            </b-row>
                            <b-row
                              v-if="
                                row.item.type == 'pg' ||
                                row.item.type == 'multiple_choice' ||
                                row.item.type == 'benar_salah'
                              "
                            >
                              <b-col class="mb-1">
                                <strong>Pertanyaan : </strong>
                                <b-card border-variant="dark">
                                  <b-card-text
                                    :id="row.item.id"
                                    v-html="row.item.content"
                                  ></b-card-text>
                                </b-card>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="">
                                <div
                                  v-if="
                                    row.item.type == 'pg' ||
                                    row.item.type == 'multiple_choice' ||
                                    row.item.type == 'benar_salah'
                                  "
                                >
                                  <!-- <b-tabs class=""> -->

                                  <div
                                    v-for="(data, index) in row.item.options"
                                    :key="index"
                                  >
                                    <b-card border-variant="dark">
                                      <!-- <template #title>
                                      <div> -->
                                      <b-col>
                                        <b-container class="bv-example-row">
                                          <b-row
                                            class="justify-content-md-center"
                                          >
                                            <template
                                              v-if="row.item.type == 'pg'"
                                            >
                                              <b-col
                                                :class="
                                                  data.is_correct == 1
                                                    ? 'bg-success'
                                                    : 'bg-white'
                                                "
                                                md="1"
                                              >
                                                <!-- <p> -->
                                                {{
                                                  index + 1 == 1
                                                    ? "A. "
                                                    : index + 1 == 2
                                                    ? "B. "
                                                    : index + 1 == 3
                                                    ? "C. "
                                                    : index + 1 == 4
                                                    ? "D. "
                                                    : index + 1 == 5
                                                    ? "E. "
                                                    : "-"
                                                }}
                                              </b-col>
                                              <b-col md="11">
                                                <span
                                                  :id="data.id"
                                                  v-html="data.content"
                                                ></span>
                                                <!-- </p> -->
                                              </b-col>
                                            </template>
                                            <template
                                              v-if="
                                                row.item.type ==
                                                  'multiple_choice' ||
                                                row.item.type == 'benar_salah'
                                              "
                                            >
                                              <b-col
                                                :class="
                                                  data.is_correct == 1
                                                    ? 'bg-success'
                                                    : 'bg-white'
                                                "
                                                md="1"
                                              >
                                                <!-- <p> -->
                                                {{
                                                  index + 1 == 1
                                                    ? "A. "
                                                    : index + 1 == 2
                                                    ? "B. "
                                                    : index + 1 == 3
                                                    ? "C. "
                                                    : index + 1 == 4
                                                    ? "D. "
                                                    : index + 1 == 5
                                                    ? "E. "
                                                    : "-"
                                                }}
                                              </b-col>
                                              <b-col md="8">
                                                <span
                                                  :id="data.id"
                                                  v-html="data.content"
                                                ></span>
                                                <!-- </p> -->
                                              </b-col>
                                              <b-col col lg="3">
                                                <div>
                                                  <label
                                                    class="font-weight-bold"
                                                    v-if="index + 1 == 1"
                                                    >Jawaban Opsi. A</label
                                                  >
                                                  <label
                                                    class="font-weight-bold"
                                                    v-if="index + 1 == 2"
                                                    >Jawaban Opsi. B</label
                                                  >
                                                  <label
                                                    class="font-weight-bold"
                                                    v-if="index + 1 == 3"
                                                    >Jawaban Opsi. C</label
                                                  >
                                                  <label
                                                    class="font-weight-bold"
                                                    v-if="index + 1 == 4"
                                                    >Jawaban Opsi. D</label
                                                  >
                                                  <label
                                                    class="font-weight-bold"
                                                    v-if="index + 1 == 5"
                                                    >Jawaban Opsi. E</label
                                                  >
                                                  <b-form-checkbox
                                                    :id="data.id"
                                                    v-if="
                                                      row.item.type ==
                                                      'multiple_choice'
                                                    "
                                                    v-model="data.is_correct"
                                                    value="1"
                                                    class="custom-control-primary"
                                                  >
                                                    Kunci Jawaban
                                                  </b-form-checkbox>
                                                </div>
                                                <div
                                                  v-if="
                                                    row.item.type ==
                                                    'benar_salah'
                                                  "
                                                >
                                                  {{
                                                    data.is_true_or_false ==
                                                    "benar"
                                                      ? "Benar"
                                                      : "Salah"
                                                  }}
                                                </div>
                                              </b-col>
                                            </template>
                                          </b-row>
                                        </b-container>
                                      </b-col>
                                    </b-card>
                                  </div>
                                </div>
                                <div v-if="row.item.type == 'pauli'">
                                  <!-- <b-tabs class=""> -->

                                  <div
                                    v-for="(data, index) in row.item.sub_soals"
                                    :key="index"
                                  >
                                    <!-- <b-col> -->
                                    <b-container class="bv-example-row">
                                      <b-row class="justify-content-md-center">
                                        <b-col md="2">
                                          <b-card border-variant="dark">
                                            <span
                                              :id="data.id"
                                              v-html="data.content"
                                            ></span>
                                          </b-card>
                                        </b-col>
                                        <b-col md="2">
                                          <b-card border-variant="dark">
                                            <span
                                              :id="data.id"
                                              v-html="data.secret_key"
                                            ></span>
                                          </b-card>
                                        </b-col>
                                      </b-row>
                                    </b-container>
                                    <!-- </b-col> -->
                                  </div>
                                </div>
                              </b-col>
                            </b-row>

                            <b-button
                              size="sm"
                              variant="outline-secondary"
                              @click="row.toggleDetails"
                              class="mt-1"
                            >
                              Hide Details
                            </b-button>
                          </b-card>
                        </template>
                        <template #cell(show_details2)="row">
                          <b-form-checkbox
                            v-model="row.detailsShowing"
                            plain
                            class="vs-checkbox-con"
                            @change="row.toggleDetails"
                          >
                            <span class="vs-checkbox">
                              <span class="vs-checkbox--check">
                                <i class="vs-icon feather icon-check" />
                              </span>
                            </span>
                            <span class="vs-label">{{
                              row.detailsShowing ? "Hide" : "Show"
                            }}</span>
                          </b-form-checkbox>
                        </template>

                        <template #row-details2="row">
                          <b-card>
                            <b-row class="">
                              <b-col md="4" class="mb-1">
                                <strong>Tipe Soal : </strong>{{ row.item.type }}
                              </b-col>
                              <b-col md="4" class="mb-1">
                                <strong>Bobot : </strong>{{ row.item.bobot }}
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="mb-1">
                                <strong>Pertanyaan : </strong>
                                <quill-editor
                                  :id="row.item.id"
                                  v-model="row.item.content"
                                  :options="editor_options"
                                />
                              </b-col>
                            </b-row>
                            <b-row>
                              <div
                                v-if="
                                  row.item.type == 'pg' ||
                                  row.item.type == 'multiple_choice' ||
                                  row.item.type == 'benar_salah'
                                "
                              >
                                <b-tabs class="mt-2">
                                  <div
                                    v-for="(data, index) in row.item.options"
                                    :key="index"
                                  >
                                    <b-tab>
                                      <template #title>
                                        <div>
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 1"
                                            >Opsi. A</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 2"
                                            >Opsi. B</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 3"
                                            >Opsi. C</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 4"
                                            >Opsi. D</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 5"
                                            >Opsi. E</label
                                          >
                                        </div>
                                      </template>
                                      <b-form>
                                        <b-row>
                                          <b-col>
                                            <quill-editor
                                              id="Konten"
                                              v-model="data.content"
                                              :options="editor_options"
                                            />
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col
                                            md="2"
                                            v-if="
                                              row.item.type ==
                                                'multiple_choice' ||
                                              row.item.type == 'benar_salah'
                                            "
                                          >
                                            <label
                                              class="font-weight-bold mt-2"
                                              v-if="index + 1 == 1"
                                              >Jawaban Opsi. A</label
                                            >
                                            <label
                                              class="font-weight-bold mt-2"
                                              v-if="index + 1 == 2"
                                              >Jawaban Opsi. B</label
                                            >
                                            <label
                                              class="font-weight-bold mt-2"
                                              v-if="index + 1 == 3"
                                              >Jawaban Opsi. C</label
                                            >
                                            <label
                                              class="font-weight-bold mt-2"
                                              v-if="index + 1 == 4"
                                              >Jawaban Opsi. D</label
                                            >
                                            <label
                                              class="font-weight-bold mt-2"
                                              v-if="index + 1 == 5"
                                              >Jawaban Opsi. E</label
                                            >
                                            <b-form-checkbox
                                              v-if="
                                                row.item.type ==
                                                'multiple_choice'
                                              "
                                              v-model="data.is_correct"
                                              value="1"
                                              class="custom-control-primary"
                                            >
                                              Kunci Jawaban
                                            </b-form-checkbox>
                                            <b-form-radio-group
                                              v-if="
                                                row.item.type == 'benar_salah'
                                              "
                                              v-model="data.is_true_or_false"
                                              :options="bsSoal"
                                              name="radios-stacked"
                                              stacked
                                            />
                                          </b-col>
                                          <!-- <b-col md="10" v-if="row.item.type != 'pg'">
                    <label class="font-weight-bold mt-2"
                      >Upload file format: jpg, png, pdf, doc, mp3,
                      mp4 (opsional)</label
                    >
                    <b-form-file
                      :id="'file' + data.id"
                      class="mb-2"
                      v-model="data.opsi_file"
                      placeholder="Pilih file untuk di upload atau unggah..."
                      drop-placeholder="Drop file here..."
                      no-drop
                      accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                    />
                  </b-col> -->
                                          <!-- <b-col md="12" v-if="row.item.type == 'pg'">
                    <label class="font-weight-bold mt-2"
                      >Upload file format: jpg, png, pdf, doc, mp3,
                      mp4 (opsional)</label
                    >
                    <b-form-file
                      :id="'file' + data.id"
                      class="mb-2"
                      v-model="data.opsi_file"
                      placeholder="Pilih file untuk di upload atau unggah..."
                      drop-placeholder="Drop file here..."
                      no-drop
                      accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                    />
                  </b-col> -->
                                        </b-row>
                                      </b-form>
                                    </b-tab>
                                  </div>
                                </b-tabs>
                              </div>
                            </b-row>

                            <b-button
                              size="sm"
                              variant="outline-secondary"
                              @click="row.toggleDetails"
                            >
                              Hide Details
                            </b-button>
                          </b-card>
                        </template>
                        <!-- A custom formatted column -->
                        <template #cell(tgl)="data">
                          {{ humanDate(data.item.info_start) }} s/d
                          {{ humanDate(data.item.info_end) }}
                        </template>

                        <template #cell(check)="data">
                          <b-form-checkbox
                            :value="data.item"
                            v-model="select_soal"
                          />
                        </template>
                      </b-table>

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                      />
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-overlay>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4 class="mb-2">
                  <strong>Soal Ujian di Jadwal ({{ judulsoal.length }})</strong>
                </h4>
                <b-table
                  small
                  responsive="sm"
                  :per-page="perPage2"
                  :current-page="currentPage2"
                  :sort-by.sync="sortBy2"
                  :sort-desc.sync="sortDesc2"
                  :sort-direction="sortDirection2"
                  :filter="filter2"
                  :filter-included-fields="filterOn2"
                  @filtered="onFiltered2"
                  :fields="selectedSoalFields"
                  :items="judulsoal"
                >
                  <template #cell(hapus)="{ item }">
                    <feather-icon
                      icon="TrashIcon"
                      size="24"
                      class="text-danger cursor-pointer"
                      @click="removeSavedSoal(item)"
                    ></feather-icon>
                  </template>
                  <!-- A virtual column -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <!-- A custom formatted column -->
                  <template #cell(action)="{ item }">
                    <a
                      v-if="item.unsaved"
                      href="#"
                      class="cursor-pointer"
                      @click.prevent="removeSoal(item)"
                    >
                      <feather-icon
                        class="text-danger"
                        icon="MinusCircleIcon"
                        size="24"
                      />
                    </a>
                  </template>
                </b-table>

                <b-pagination
                  v-model="currentPage2"
                  :total-rows="totalRows2"
                  :per-page="perPage2"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click.prevent="update" class="mb-1">
            Simpan soal yang dipilih</b-button
          >
        </div>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import {
  BOverlay,
  BFormTextarea,
  BFormFile,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  props: ["ujianDataId"],
  components: {
    vSelect,
    quillEditor,
    BTabs,
    BTab,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      filterPaket: this.ujianDataId.category_paket_id,
      optPaket: [],
      filterMapel: null,
      optMapel: [],
      loading: false,
      filterMapel: null,
      optMapel: [],
      filterTipe: null,
      optTipe: [
        { id: "pg", value: "Pilihan Ganda" },
        { id: "multiple_choice", value: "Multiple Choice" },
        { id: "benar_salah", value: "Benar Salah" },
        { id: "pauli", value: "Pauli" },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      judulsoal: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      soalData: [],
      mapelData: [],
      form: {
        id: null,
        name: null,
        price: null,
        thumbnail: null,
        description: null,
        info_start: new Date(),
        info_end: new Date(),
        max_quota: null,
        sum_subtest: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table Soal
      fields: [
        { key: "index", label: "No" },
        { key: "check", label: "Pilih", sortable: true },
        // { key: "mapel.nama_mapel", label: "Mata Pelajaran", sortable: true },
        { key: "name", label: "Judul Soal", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "show_details", label: "#" },
        // { key: "action", label: "#" },
      ],
      selectedSoalFields: [
        { key: "index", label: "No" },
        { key: "name", label: "Judul Soal", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "action", label: "#" },
      ],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      //Table Soal pilihan
      fields2: [
        { key: "index", label: "No" },
        // { key: "mapel", label: "Mata Pelajaran", sortable: true },
        { key: "name", label: "Judul Soal", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "bobot", label: "Bobot Soal", sortable: true },
        { key: "show_details", label: "#" },
        { key: "hapus", label: "Hapus" },
      ],
      total2: [],
      record2: [],
      optFilter2: [
        { id: "name", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage2: 10,
      pageOptions2: [10, 30, 50],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      // filter: "Paket",
      search: null,
      idd: null,
      loading: false,
      selectMode: "multi",
      selected: [],
      select_soal: [],
      soalUjianData: [],
    };
  },
  watch: {
    ujianDataId(val) {
      if (val) {
        this.getBankSoal();
      }
    },
    perPage(value) {
      if (value) {
        this.getBankSoal();
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getBankSoal();
      }
    },
    currentPage(val) {
      if (val) {
        this.getBankSoal();
      }
    },
    select_soal(array) {
      let id_soal = array.map((item) => item.id);
      // add prop unsaved: true for difference existing soal ujian and new soal ujian
      let selectedSoals = array.map((soal) => {
        soal.unsaved = true;
        return soal;
      });

      // remove first selected soal yng sudah di judul soal
      this.judulsoal = this.judulsoal.filter((soal) => !soal.unsaved);
      // foilter jangan sampe memasukan soal uyg sama 2x
      const filterSoalUjianIds = this.judulsoal.map((soal) => soal.id);
      selectedSoals = selectedSoals.filter((soal) => {
        return !filterSoalUjianIds.some((soalId) => soalId == soal.id);
      });

      this.judulsoal = [...this.judulsoal, ...selectedSoals];
      this.totalRows2 = this.judulsoal.length;
      const soalIds = this.judulsoal.map((soal) => soal.id);
      this.ujianDataId.soal_ids = soalIds.join(",");
    },
    currentPage(val) {
      if (val) {
        this.getBankSoal();
      }
    },
    perPage(val) {
      if (val) {
        this.getBankSoal();
      }
    },
  },
  computed: {
    sumBobotSoal() {
      if (this.soalUjianData.length < 1) return 0;

      return this.soalUjianData.reduce((total, soal) => {
        total += parseInt(soal.bobot);
        return total;
      }, 0);
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fields2
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    removeSoal(item) {
      const indexSoal = this.judulsoal.findIndex(
        (soal) => soal.id == item.id && soal.unsaved
      );
      if (indexSoal > -1) {
        this.judulsoal.splice(indexSoal, 1);
      }
    },
    openBanksoalModal() {
      this.$bvModal.show("banksoal-modal");
      this.getBankSoal();
    },
    async removeSavedSoal(soal) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const selectedSoalId = soal.id;
          let soalIds = this.soalUjianData.map((soal) => soal.id);
          const findSoalIndex = soalIds.findIndex(
            (soal_id) => soal_id == selectedSoalId
          );

          if (findSoalIndex > -1) {
            // remove seleted index from soalIds
            soalIds.splice(findSoalIndex, 1);
            soalIds = soalIds.join(",");

            // update
            const payload = this.ujianDataId;
            payload.soal_ids = soalIds;

            if (this.$route.query?.j) {
              payload.jadwal_id = this.$route.query?.j;
            }
            this.loading = true;
            this.$store
              .dispatch("adminujian/save", [payload])
              .then(() => {
                // this.ujianDataId
                this.getData();
                this.displaySuccess({
                  text: "Data soal ujian berhasil diperbaharui",
                });
                this.loading = false;
              })
              .catch((e) => {
                this.loading = false;
                this.displayError(e);
                return false;
              });
          }
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    update() {
      this.loading = true;
      if (this.ujianDataId.name == null || this.ujianDataId.name == "") {
        this.pesanGagal();
        return false;
      }

      let payload = {
        id: this.$route.query.ujian_id,
        user_id: this.user.id,
        name: this.ujianDataId.name,
        shortname: this.ujianDataId.shortname,
        soal_ids: this.ujianDataId.soal_ids,
        shuffle_question: this.ujianDataId.shuffle_question,
        category_paket_id: this.ujianDataId.category_paket_id,
        show_result: this.ujianDataId.show_result,
        instruction: this.ujianDataId.instruction,
        min_nilai: this.ujianDataId.min_nilai,
      };

      if (this.$route.query?.j) {
        payload.jadwal_id = this.$route.query?.j;
      }

      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          this.loading = false;
          this.ujianDataId;
          this.getData();
          this.$bvModal.hide("banksoal-modal");
          this.displaySuccess({
            text: "Soal Ujian di jadwal berhasil diperbaharui",
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const fd = new FormData();
          fd.append(`data[0][id]`, data.id);
          fd.append(`data[0][fungsi]`, (data.fungsi = 1));
          // data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", fd)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getBankSoal() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        category_paket_id: this.ujianDataId?.category_paket_id,
        user_id: this.user?.id,
        // ujian_id: this.$route.query?.ujian_id,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      };

      if (this.filterTipe) {
        params.type = this.filterTipe?.id;
      }

      if (this.filter) {
        params.search = this.filter;
      }

      try {
        const response = await this.$store.dispatch("banksoal/index", params);
        let { data: soals, totalRecords } = response.data;
        if (this.judulsoal.length > 0) {
          // filter di bank soal jangan tampilkan soal yg sudah diselect
          const soalUjianIds = this.judulsoal.map((item) => item.id);
          soals = soals.filter((soal) => !soalUjianIds.includes(soal.id));
        }

        this.soalData = soals;
        this.totalRows = totalRecords;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getData() {
      this.loading = true;

      const payload = {
        ujian_id: this.$route.query?.ujian_id,
      };
      if (this.$route.query?.j) {
        payload.jadwal_id = this.$route.query?.j;
      }
      this.$store
        .dispatch("banksoal/indexSoalUjian", payload)
        .then((response) => {
          this.loading = false;
          const { data, totalRecords } = response.data;
          this.soalUjianData = data;
          this.judulsoal = data;
          this.totalRows2 = totalRecords;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let optPaket = response.data.data;
          optPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optPaket = optPaket;
          // this.totalRows = this.katsoalData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  async created() {
    this.getData();
    this.getDataKategoriPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
