<template v-if="allowCreate()">
  <section>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-overlay :show="loading">
            <b-card-body>
              <b-card-title>
                <b-row>
                  <b-col>
                    <div>Bank Soal</div>
                  </b-col>
                  </b-row>
              </b-card-title>
              <b-card-text class="blog-content-truncate">
                <b-row>
                  <b-col md="3" sm="3" class="">
                    <label>Pilih Paket</label>
                    <v-select label="text" v-model="filterPaket" class="select-size-sm w-full" @input="getData()"
                      :clearable="true" :options="optPaket" />
                  </b-col>
                  <b-col md="3" sm="3" class="">
                    <label>Pilih Tipe</label>
                    <v-select label="value" v-model="filterTipe" class="select-size-sm w-full" @input="getData()"
                      :clearable="true" :options="optTipe" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                      <label class="d-inline-block text-sm-left mr-50">Per page</label>
                      <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" sm="8" class="my-1">
                    <b-form-group label="Sort" label-cols-sm="2" label-align-sm="right" label-size="sm"
                      label-for="sortBySelect" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-50">
                          <template v-slot:first>
                            <option value="">-- none --</option>
                          </template>
                        </b-form-select>
                        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-30">
                          <option :value="false">Asc</option>
                          <option :value="true">Desc</option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5" class="my-1">
                    <b-form-group label-for="filterInput" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Tekan enter untuk cari"
                          @keydown.enter.prevent="getData()" />
                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="
                            filter = '';
                          getData();
                          ">
                            Clear
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-table small responsive striped :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :fields="fields"
                      :items="soalData">
                      <!-- :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered" -->
                      <!-- A virtual column -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <i class="feather icon-disc primary" />
                        </template>

                        <template v-else>
                          <i class="feather icon-circle" />
                        </template>
                      </template>

                      <template #cell(show_details)="row">
                        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con"
                          @change="row.toggleDetails">
                          <span class="vs-checkbox">
                            <span class="vs-checkbox--check">
                              <i class="vs-icon feather icon-check" />
                            </span>
                          </span>
                          <span class="vs-label">{{
                            row.detailsShowing ? "Hide" : "Show"
                          }}</span>
                        </b-form-checkbox>
                      </template>

                      <template #row-details="row">
                        <b-card>
                          <b-row class="">
                            <b-col md="4" class="mb-1">
                              <strong>Tipe Soal : </strong>{{ row.item.type }}
                            </b-col>
                            <b-col md="4" class="mb-1">
                              <strong>Bobot : </strong>{{ row.item.bobot }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="mb-1">
                              <strong>Pertanyaan : </strong>
                              <quill-editor :id="row.item.id" v-model="row.item.content" :options="editor_options" />
                            </b-col>
                          </b-row>
                          <b-row>
                            <div v-if="row.item.type == 'pg' ||
                              row.item.type == 'multiple_choice' ||
                              row.item.type == 'benar_salah'
                              ">
                              <b-tabs class="mt-2">
                                <div v-for="(data, index) in row.item.options" :key="index">
                                  <b-tab>
                                    <template #title>
                                      <div>
                                        <label class="font-weight-bold" v-if="index + 1 == 1">Opsi. A</label>
                                        <label class="font-weight-bold" v-if="index + 1 == 2">Opsi. B</label>
                                        <label class="font-weight-bold" v-if="index + 1 == 3">Opsi. C</label>
                                        <label class="font-weight-bold" v-if="index + 1 == 4">Opsi. D</label>
                                        <label class="font-weight-bold" v-if="index + 1 == 5">Opsi. E</label>
                                      </div>
                                    </template>
                                    <b-form>
                                      <b-row>
                                        <b-col>
                                          <quill-editor id="Konten" v-model="data.content" :options="editor_options" />
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col md="2" v-if="row.item.type == 'multiple_choice' ||
                                          row.item.type == 'benar_salah'
                                          ">
                                          <label class="font-weight-bold mt-2" v-if="index + 1 == 1">Jawaban Opsi.
                                            A</label>
                                          <label class="font-weight-bold mt-2" v-if="index + 1 == 2">Jawaban Opsi.
                                            B</label>
                                          <label class="font-weight-bold mt-2" v-if="index + 1 == 3">Jawaban Opsi.
                                            C</label>
                                          <label class="font-weight-bold mt-2" v-if="index + 1 == 4">Jawaban Opsi.
                                            D</label>
                                          <label class="font-weight-bold mt-2" v-if="index + 1 == 5">Jawaban Opsi.
                                            E</label>
                                          <b-form-checkbox v-if="row.item.type == 'multiple_choice'"
                                            v-model="data.is_correct" value="1" class="custom-control-primary">
                                            Kunci Jawaban
                                          </b-form-checkbox>
                                          <b-form-radio-group v-if="row.item.type == 'benar_salah'"
                                            v-model="data.is_true_or_false" :options="bsSoal" name="radios-stacked"
                                            stacked />
                                        </b-col>
                                        <!-- <b-col md="10" v-if="row.item.type != 'pg'">
                                          <label class="font-weight-bold mt-2"
                                            >Upload file format: jpg, png, pdf, doc, mp3,
                                            mp4 (opsional)</label
                                          >
                                          <b-form-file
                                            :id="'file' + data.id"
                                            class="mb-2"
                                            v-model="data.opsi_file"
                                            placeholder="Pilih file untuk di upload atau unggah..."
                                            drop-placeholder="Drop file here..."
                                            no-drop
                                            accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                                          />
                                        </b-col> -->
                                        <!-- <b-col md="12" v-if="row.item.type == 'pg'">
                                          <label class="font-weight-bold mt-2"
                                            >Upload file format: jpg, png, pdf, doc, mp3,
                                            mp4 (opsional)</label
                                          >
                                          <b-form-file
                                            :id="'file' + data.id"
                                            class="mb-2"
                                            v-model="data.opsi_file"
                                            placeholder="Pilih file untuk di upload atau unggah..."
                                            drop-placeholder="Drop file here..."
                                            no-drop
                                            accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                                          />
                                        </b-col> -->
                                      </b-row>
                                    </b-form>
                                  </b-tab>
                                </div>
                              </b-tabs>
                            </div>
                          </b-row>

                          <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
                            Hide Details
                          </b-button>
                        </b-card>
                      </template>
                      <!-- A custom formatted column -->
                      <template #cell(tgl)="data">
                        {{ humanDate(data.item.info_start) }} s/d
                        {{ humanDate(data.item.info_end) }}
                      </template>

                      <template #cell(check)="data">
                        <b-form-checkbox :value="data.item" v-model="select_soal" />
                        <!-- <b-form-checkbox :value="data.item" v-model="select_soal" /> -->
                      </template>
                      <template #cell(max_quota)="data">
                        {{ formatRupiah(data.item.max_quota) }}
                      </template>

                      <template #cell(color)="data">
                        <b-badge :variant="data.item.color">
                          {{ data.item.color }}
                        </b-badge>
                      </template>
                      <template #cell(type)="data">
                        {{
                          data.item.type == "pg"
                          ? "PG"
                          : data.item.type == "multiple_choice"
                            ? "Multiple Choice"
                            : data.item.type == "benar_salah"
                              ? "Benar Salah"
                              : "Pauli"
                        }}
                      </template>
                    </b-table>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"
                      size="sm" class="my-0" />
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Soal yang sebelumnya dipilih">
          <b-row>
            <b-col>

              <b-table small responsive="sm" :per-page="perPage2" :current-page="currentPage2" :sort-by.sync="sortBy2"
                :sort-desc.sync="sortDesc2" :sort-direction="sortDirection2" :filter="filter2"
                :filter-included-fields="filterOn2" @filtered="onFiltered2" :fields="fields2" :items="soalsebelum">
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(type)="data">
                  {{
                    data.item.type == "pg"
                    ? "PG"
                    : data.item.type == "multiple_choice"
                      ? "Multiple Choice"
                      : data.item.type == "benar_salah"
                        ? "Benar Salah"
                        : "Pauli"
                  }}
                </template>
              </b-table>

              <b-pagination v-model="currentPage2" :total-rows="totalRows2" :per-page="perPage2" align="center" size="sm"
                class="my-0" />
            </b-col> </b-row>
          <!-- <div class="d-flex justify-content-start">
            <b-button variant="primary" @click.prevent="update" class="mb-1">
              Simpan</b-button>
          </div> -->
          <!-- {{ pilih }} -->
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Soal yang sekarang">
          <b-row>
            <b-col>

              <b-table
                small
                responsive="sm"
                :per-page="perPage2"
                :current-page="currentPage2"
                :sort-by.sync="sortBy2"
                :sort-desc.sync="sortDesc2"
                :sort-direction="sortDirection2"
                :filter="filter2"
                :filter-included-fields="filterOn2"
                @filtered="onFiltered2"
                :fields="fields2"
                :items="pilih"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(type)="data">
                  {{
                    data.item.type == "pg"
                      ? "PG"
                      : data.item.type == "multiple_choice"
                      ? "Multiple Choice"
                      : data.item.type == "benar_salah"
                      ? "Benar Salah"
                      : "Pauli"
                  }}
                </template>
              </b-table>

              <b-pagination
                v-model="currentPage2"
                :total-rows="totalRows2"
                :per-page="perPage2"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col> </b-row>
            <div class="d-flex justify-content-start mt-1">
              <b-button variant="primary" @click.prevent="update" class="mr-1" >
                Simpan</b-button>
              <b-button variant="primary" @click="this.pilih = null">
                Reset</b-button>
            </div>
          </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import {
  BOverlay,
  BFormTextarea,
  BFormFile,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  props: ["ujianDataId"],
  components: {
    vSelect,
    quillEditor,
    BTabs,
    BTab,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      filterPaket: null,
      optPaket: [],
      filterMapel: null,
      optMapel: [],
      filterTipe: null,
      optTipe: [
        { id: "pg", value: "Pilihan Ganda" },
        { id: "multiple_choice", value: "Multiple Choice" },
        { id: "benar_salah", value: "Benar Salah" },
        { id: "pauli", value: "Pauli" },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      soalsebelum: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      soalData: [],
      mapelData: [],
      form: {
        id: null,
        name: null,
        price: null,
        thumbnail: null,
        description: null,
        info_start: new Date(),
        info_end: new Date(),
        max_quota: null,
        sum_subtest: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table Soal
      fields: [
        { key: "index", label: "No" },
        { key: "check", label: "Pilih", sortable: true },
        // { key: "mapel.nama_mapel", label: "Mata Pelajaran", sortable: true },
        { key: "name", label: "Judul Soal", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "show_details", label: "#" },
        // { key: "action", label: "#" },
      ],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      //Table Soal pilihan
      fields2: [
        { key: "index", label: "No" },
        // { key: "mapel", label: "Mata Pelajaran", sortable: true },
        { key: "name", label: "Judul Soal", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "show_details", label: "#" },
        // { key: "action", label: "#" },
      ],
      total2: [],
      record2: [],
      optFilter2: [
        { id: "name", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage2: 10,
      pageOptions2: [10, 30, 50],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      // filter: "Paket",
      search: null,
      idd: null,
      loading: false,
      selectMode: "multi",
      selected: [],
      select_soal: [],
      pilih: [],
      pilihsave:null,
    };
  },
  watch: {
    select_soal(array) {
      if (array && array.length > 0) {
        let id_soal = array.map((item) => item.id);
        // let judul_soal = array.map((item) => item.id);
        this.pilihsave = id_soal.join(",");
        this.pilih = array;
        // this.soalsebelum = array;
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fields2
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    update() {
      if (this.ujianDataId.name == null || this.ujianDataId.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        id:this.ujianDataId.id,
        // type_ujian: this.ujianDataId.type_ujian,
        user_id: this.user.id,
        // ujian_id: this.$route.query.ujian_id,
        name: this.ujianDataId.name,
        shortname: this.ujianDataId.shortname,
        soal_ids: this.pilihsave,
        shuffle_question: this.ujianDataId.shuffle_question,
        category_paket_id: this.ujianDataId.category_paket_id,
        show_result: this.ujianDataId.show_result,
        instruction: this.ujianDataId.instruction,
      };

      // if (this.id) {
      //   payload.id = this.id;
      // }

      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          if (this.activeAction == "ubah") {
            this.id = null;
          }
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.ujianDataId;
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const fd = new FormData();
          fd.append(`data[0][id]`, data.id);
          fd.append(`data[0][fungsi]`, (data.fungsi = 1));
          // data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", fd)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        filter_by: "name",
        search: this.filter != null ? this.filter : null,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        category_paket_id: this.filterPaket != null ? this.filterPaket.value : null,
        // mapel_id: this.ujianDataId.mapel.id,
        // mapel_id: this.filterMapel != null ? this.filterMapel.value : null,
        type: this.filterTipe != null ? this.filterTipe.id : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("banksoal/index", payload)
        .then((response) => {
          this.loading = false;
          let soalData = response.data.data;
          this.soalData = soalData;
          this.totalRows = this.soalData.length;
          // let cek1 = this.ujianDataId.soal_ids.split(",").map((s) => s.trim());
          // let select = cek1.map((id) => soalData.find((x) => x.id == id));
          // this.select_soal = select
          // this.doFormatRupiah();
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    async getDataMapel() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("mapel/index", payload)
        .then((response) => {
          let mapelData = response.data.data;
          mapelData.map((item) => {
            item.value = item.id;
            item.text = item.nama_mapel;
          });
          this.mapelData = mapelData;
          this.optMapel = mapelData;
          // this.totalRows = this.mapelData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataSoalPilihSebelumnya() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        filter_by: "name",
        search: this.filter != null ? this.filter : null,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        category_paket_id: this.filterPaket != null ? this.filterPaket.value : null,
        ujian_id: this.$route.query.ujian_id != null ? this.$route.query.ujian_id : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("banksoal/index", payload)
        .then((response) => {
          this.loading = false;
          let soalsebelum = response.data.data;
          this.soalsebelum = soalsebelum;
          this.totalRows = this.soalsebelum.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
    },
    async getDataKategoriPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let optPaket = response.data.data;
          optPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optPaket = optPaket;
          // this.totalRows = this.katsoalData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() { },
  async mounted() {
    // this.getData();
    this.getDataSoalPilihSebelumnya()
    this.getDataKategoriPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
